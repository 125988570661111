import React from 'react'
import Layout from "../../components/App/Layout"
import Navbar from "../../components/App/Navbar"
import PageBanner from '../../components/Common/PageBanner'
import Footer from "../../components/App/Footer"
// import IndustryDetailsContent from '../../components/Industries/IndustryDetailsContent'
import DevopsContent from '../../components/Services/DevopsContent'
import RelatedServices from '../../components/Industries/RelatedServices'
import NavbarTwo from '../../components/App/NavbarTwo'
 
const Details = () => {
    return (
        <Layout>
            <Navbar />
            <DevopsContent />
            {/* <RelatedServices /> */}
            <Footer />
        </Layout>
    );
}

export default Details